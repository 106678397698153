import React from 'react';
import { Column, Container, Row } from '../../components/Grid';
import Layout from '../../components/Layout';
import Resource from '../../components/Resource';
import Section from '../../components/Section';

const DigitalLessonBundles = () => {
  return (
    <Layout title="Digital Lesson Bundles">
      <Section>
        <Container>
          <Row>
            <Column size={12} className="pb-1">
              <h2>Digital Lesson Bundles</h2>
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={12}>
              <div className="family-resources__row_divider">
                <h4>CAREER EXPLORATION DIGITAL LESSON BUNDLE</h4>
              </div>
            </Column>
            <Column size={12} className="pt-1">
              <p>
                This Digital Lesson Bundle (DLB) has everything teachers and
                their students need to explore and prepare for a professional
                internship, inclusive of multiple career paths. Students work
                through the lesson alone at their own pace and then bring
                together their learnings in groups, while the accompanying
                teacher guide ensures a focused, enriching experience.
              </p>
            </Column>
          </Row>
          <Row>
            <Column className="digital-bundles-columns" size={8}>
              <Resource
                img="STEM-image-DLB-career.jpg"
                subject="Career Exploration"
                audience="Grades 9–12"
                duration="Two to three class sessions of approximately 45 minutes each "
                description="Building upon the self-paced module (or used independently), students work in groups using the 4Cs to research future potential careers and possible paths to get them there. Students will then communicate what they have learned to their classmates."
                actions={[
                  {
                    label: 'Digital Lesson',
                    link: '/pdfs/Trane-DLB-CareerExploration.pptx',
                    metadata: '.PPT 7.1MB',
                  },
                ]}
              />
            </Column>
            <Column className="digital-bundles-columns" size={4}>
              <Resource
                img="STEM-graphic-DLB-EdGuide2.png"
                subject="Educator Guide"
                description="Students will apply and practice the 21st Century Skills—including the 4Cs—they learned about in the self-paced module by researching potential careers and career paths in groups, with tasks delegated to each member by group consensus.  An accompanying PowerPoint provides step-by-step instructions for the activity. "
                actions={[
                  {
                    label: 'Educator Guide',
                    download:
                      '/pdfs/SustainableFutures-DLB-CareerExploration.pdf',
                    metadata: '.PDF 508KB',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <Row>
            <Column size={12}>
              <div className="family-resources__row_divider">
                <h4>HEALTHY SPACES DIGITAL LESSON BUNDLE</h4>
              </div>
            </Column>
            <Column size={12} className="pt-1">
              <p>
                Explore the ways that students’ environment impacts their lives
                with this hands-on learning exploration. Students will be
                introduced to Trane Technology's Center for Healthy and
                Efficient Spaces (CHES) as they learn how to evaluate the
                environmental quality of the indoor spaces in their lives.
              </p>
            </Column>
          </Row>
          <Row>
            <Column size={8}>
              <Resource
                img="STEM-image-DLB-trees.jpg"
                subject="Healthy Spaces"
                audience="Middle & High School"
                duration="Duration or Length"
                description="Inspire students to investigate Indoor Environmental Quality (IEQ) in their own community. After learning the meaning of healthy spaces, students will apply what they’ve learned to an indoor space in their school through a persuasive writing challenge."
                actions={[
                  {
                    label: 'Digital Lesson',
                    link: '/pdfs/Trane-DLB-HealthySpaces-V2.pptx',
                    metadata: '.PPT 95MB',
                  },
                ]}
              />
            </Column>
            <Column size={4}>
              <Resource
                img="STEM-graphic-DLB-EdGuide.png"
                subject="Educator Guide"
                title="Does indoor environmental quality impact your learning environment?"
                description="Get helpful tools and strategies for implementing the Healthy Spaces lesson in your class with this comprehensive guide, including key background info, standards alignment, video transcript and more."
                actions={[
                  {
                    label: 'Educator Guide',
                    download:
                      '/pdfs/SustainableFutures-DLB-HealthySpaces-V1.0.pdf',
                    metadata: '.PDF 1.3MB',
                  },
                ]}
              />
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default DigitalLessonBundles;
